import { toFloatTime } from '@kpv-lab/time-utils'

export const templateFieldProps = {
  date:           { type: 'date' },
  time:           { type: 'time' },
  number:         { type: 'number', updateOnBlur: true },
  text:           { type: 'text' },
  textarea:       { type: 'textarea', updateOnBlur: true },
  startDate:      { type: 'date' },
  endDate:        { type: 'date' },
  dateDuration:   { type: 'duration' },
  age:            { type: 'age' },
  startTime:      { type: 'time' },
  endTime:        { type: 'time' },
  duration:       { type: 'time', updateOnBlur: true },
  title:          { type: 'text' },
  subTitle:       { type: 'text' },
  name:           { type: 'text' },
  abstract:       { type: 'textarea', updateOnBlur: true },
  extended:       { type: 'richtext' },
  body:           { type: 'richtext' },
  event:          { type: 'event' },
  journey:        { type: 'journey' },
  genealogy:      { type: 'genealogy' },
  publication:    { type: 'publication' },
  chapter:        { type: 'chapter' },
  quote:          { type: 'quote' },
  insight:        { type: 'insight' },
  wiki:           { type: 'wiki' },
  children:       { type: 'text' },
  connections:    { type: 'connections' },
  reference:      { type: 'reference' },
  related:        { type: 'related' },
  category:       { type: 'category' },
  subCategory:    { type: 'tags', updateOnBlur: true },
  tags:           { type: 'tags', updateOnBlur: true },
  image:          { type: 'image', updateOnBlur: true },
  url:            { type: 'url', updateOnBlur: true },
  'wikidata-id':  { type: 'text', updateOnBlur: true },
  wikidata:       { type: 'text', updateOnBlur: true },
  richtext:       { type: 'richtext' },
  ref:            { type: 'text', updateOnBlur: true },
  divider:        { type: 'divider' },
  panel:          { type: 'panel' },
  video:          { type: 'video' },
  question:       { type: 'question' },
  'daily-events':     { type: 'daily-events' },
  topicFlag:      { type: 'topicFlag' },
}

export const complexFieldTypes = {
  'daily-events': {
    sort:   '_start',
    import: {
      start:    'start',
      title:    'title',
      subtitle: 'subtitle',
    },
    processFunc: (importSpec) => (row) => {
      const { start, text, texts, end } = row
      const [title, subtitle] = texts ?? [text, '']

      const reducedTitle = title.substring(0, 66)
      const reducedSubtitle = subtitle.substring(0, 66)

      const rowItem: any = {
        [importSpec.start]:    start,
        _start:                toFloatTime(start),
        [importSpec.title]:    reducedTitle,
        [importSpec.subtitle]: reducedSubtitle,
      }

      if (importSpec.id) {
        rowItem.id = Math.floor(100000 + Math.random() * 900000)
      }

      if (importSpec.end && end) {
        rowItem[importSpec.end] = end
        rowItem._end = toFloatTime(end)
      }

      return rowItem
    },
  },
  event: {
    import: {
      end:   'end',
      start: 'start',
      text:  'body',
    },
    sort: '_start',
  },
  publication: {
    import: {
      start:         'start',
      title:         'title',
      id:            true,
      originalTitle: 'originalTitle',
    },
    sort:        '_start',
    processFunc: (importSpec) => (row) => {
      const { start, text, texts, end } = row
      const [title, originalTitle] = texts ?? [text, '']

      const reducedTitle = title.substring(0, 66)
      const reducedOriginalTitle = originalTitle.substring(0, 66)

      const rowItem: any = {
        [importSpec.start]:         start,
        _start:                     toFloatTime(start),
        [importSpec.title]:         reducedTitle,
        [importSpec.originalTitle]: reducedOriginalTitle,
      }

      if (importSpec.id) {
        rowItem.id = Math.floor(100000 + Math.random() * 900000)
      }

      if (importSpec.end && end) {
        rowItem[importSpec.end] = end
        rowItem._end = toFloatTime(end)
      }

      return rowItem
    },
  },
  genealogy: {
    import: {
      end:   'end',
      start: 'start',
      text:  'name',
    },
  },
  chapter: true,
  quote:   true,
  wiki:    true,
  image:   true,
  video:   true,
  tags:    true,
  journey: {
    dragDisabled: true,
    sort:         '_start',
  },
  connections: true,
  reference:   {
    sort: 'title',
  },
  related:   true,
  insight:   true,
  question:  true,
  topicFlag: true,
}

export const templateFields = [
  {
    label:  'Dates',
    values: [
      { label: 'Start date', value: 'startDate' },
      { label: 'End date', value: 'endDate' },
      { label: 'Calendar duration (auto)', value: 'dateDuration' },
      { label: 'Age (auto)', value: 'age' },
    ],
  },
  {
    label:  'Times',
    values: [
      { label: 'Start time', value: 'startTime' },
      { label: 'End time', value: 'endTime' },
      { label: 'Time duration', value: 'duration' },
    ],
  },
  {
    label:  'Content',
    values: [
      { label: 'Main title', value: 'title' },
      { label: 'Sub title', value: 'subTitle' },
      { label: 'Name', value: 'name' },
      { label: 'Abstract', value: 'abstract' },
      { label: 'Extended abstract', value: 'extended' },
      { label: 'Body', value: 'body' },
      { label: 'Event', value: 'event' },
      { label: 'Quotations', value: 'quote' },
      { label: 'Connections', value: 'connections' },
      { label: 'Reference', value: 'reference' },
      { label: 'Insights', value: 'insight' },
      { label: 'Journeys', value: 'journey' },
      { label: 'Publication', value: 'publication' },
      { label: 'Daily Events', value: 'daily-events' },
      { label: 'Chapter', value: 'chapter' },
      { label: 'Genealogy', value: 'genealogy' },
      { label: 'Wiki URL', value: 'wiki' },
      { label: 'Question', value: 'question' },
      { label: 'Topic Flags', value: 'topicFlag' },
    ],
  },
  {
    label:  'Related content',
    values: [
      { label: 'Child content', value: 'children' },
      { label: 'Related item', value: 'related' },
    ],
  },
  {
    label:  'Classification',
    values: [
      { label: 'Main category', value: 'category' },
      { label: 'Sub category', value: 'subCategory' },
      { label: 'Tags', value: 'tags' },
    ],
  },
  {
    label:  'Meta data',
    values: [
      { label: 'Image', value: 'image' },
      { label: 'Video', value: 'video' },
      { label: 'URL', value: 'url' },
      { label: 'Wikipage', value: 'wiki' },
      { label: 'Wikidata ID', value: 'wikidata-id' },
      { label: 'Ref. ID', value: 'ref' },
    ],
  },
  {
    label:  'Generic',
    values: [
      { label: 'Text', value: 'text' },
      { label: 'Multi-line text', value: 'textarea' },
      { label: 'Rich text', value: 'richtext' },
      { label: 'Number', value: 'number' },
      { label: 'Date', value: 'date' },
      { label: 'Time', value: 'time' },
    ],
  },
]
